import React, { type ReactNode, createContext } from 'react'
import { UserData } from '~/services/UserService.server'

export interface IUserContext {
  userData: UserData | undefined
}

export const UserContext = createContext<IUserContext>({
  userData: undefined,
})

export const UserContextProvider: React.FC<{
  children: ReactNode
  userData: UserData | undefined
}> = ({ children, userData }) => {
  return (
    <UserContext.Provider
      value={{
        userData,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
